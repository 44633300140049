import React, { useEffect, useState } from 'react';
import AppLayout from '../../components/layout';
import { Button } from "antd";
import { connect } from "react-redux"
import { sampleFunction } from "../../actions/sampleAction";
import { Routes } from '../../utils/route'
import "./report.css"
import { Select,Spin } from "antd";
import List from "./comp/List"
import API from "../../api"
import moment from 'moment';
import PageHeader from '../../components/pageHeader/PageHeader';
import Billreports from './comp/Billreports'
import {getClosedOutList} from '../../actions/myYardAction';
import { getBillReports } from '../../actions/reportAction';


const Report = props => {
  const { customerSelect, customer_report_list } = API;
  const { Option } = Select;
  //const items = ["apple", "King of fruits", "Orange"];
  // const [select, setSelect] = useState();
  const [loading, setLoading] = useState(true)
  const [list, setList] = useState();
  // const [load, setLoad] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState('ALL');
  const [searched,setSearched]=useState('ALL')
  const [tab, onTab] = useState(0);

  useEffect(() => {
    const _yard = JSON.parse(localStorage.getItem('report'));
    onTab(_yard || 0);
  }, []);
  // useEffect(
  //   () => {
  //     localStorage.setItem('report', tab);
  //     if(tab===0){
  //         // props.getClosedOutList(0,'');
  //         // props.getBillReports(0,'')
  //       }
  //     //   else if(tab===1){
  //     //       props.getInYardList(0,''); 
  //     //     }else{
  //     //     props.getAssetList(0,'');
  //     // }
  //   },
  //   [tab]
  // );

  // useEffect(() => {
  //   customerSelect()
  //     .then((res) => {
  //       const data=res.data.data
  //       data&&data.sort((a,b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase()> a.name.toUpperCase()) ? -1 : 0))
  //       data.unshift({clients_cust_id:'ALL',name:"ALL"})
  //       setSelect(data)
  //     })
  //     .catch((er) => {
  //       console.log(er)
  //     })
  //     // customerFilterHandler()
  // }, [])
  // const selectCustomerHandler = (id) => {
  //   setSelectedCustomer(id)
  // }


  // const customerFilterHandler = () => {
  //   setLoad(true);
  //     let currentDate = moment(new Date()).format('YYYY/MM/DD').toString();
  //     customer_report_list({ customers: selectedCustomer!=="ALL"?[selectedCustomer]:[], date: currentDate })
  //       .then((res) => {
  //         setLoad(false);
  //         setSearched(selectedCustomer)
  //         setList(res.data.data)
  //       })
  //       .catch((er) => {
  //         console.log(er)
  //       })
  // }
  useEffect(() => {
    const callback = (val) => {
      if (val === true) {
        setLoading(false);
      }
    }
    Routes('manager', callback)
  }, [])

  const inYardTableNav=(id)=>{
    onTab(id);
  }

  if(!loading){
  return (
    <AppLayout>
      <PageHeader title="on yard reports"/>
      <section className="yard d-print-none">
               <div style={{display:tab===0?'block':'none'}}><Billreports tab={tab} inYardTableNav={inYardTableNav}  billList={props.billOutList}
                  count={props.billOutCount} success={props.success} loading={props.loadingBillReportOut}
               /></div>
               <div style={{display:tab===1?'block':'none'}}><List tab={tab} inYardTableNav={inYardTableNav} list={list} customerName={searched} 
                // onPageChange={onPageChange}  count={props.inYardCount} loading={props.loadingInYard} 
                /></div>
               {/* <div style={{display:tab===2?'block':'none'}}><List tab={tab} inYardTableNav={inYardTableNav}
                  // onPageChange={onPageChange}
                  closeList={props.closedOutList}
                  count={props.closedOutCount}
                  loading={props.loadingClosedOut}
                /></div> */}

        </section>
      {/* <section className="reports_section">
        <div className="reports_customer_content">
          <div className="reports_customer_select">
            <label>customer</label>
            <Select
            defaultValue="ALL"
            virtual={false}
            showSearch
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
              placeholder="Please select customer"
              optionLabelProp="label"
              style={{ width: "100%",borderRadius:"102px" }}
              onChange={(item) => { selectCustomerHandler(item) }}
            >
              {select && select.map(item => (
                <Option key={item.clients_cust_id} value={item.clients_cust_id} label={item.name}>{item.name}</Option>
              ))}
            </Select>
          </div>
          <div>OR</div>
          <div className="reports_customer_select">
            <label>equipment <span style={{marginLeft:"5px"}}>no.</span></label>
            <Select
            defaultValue="ALL"
            virtual={false}
            showSearch
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
              placeholder="Please select equipment no."
              optionLabelProp="label"
              style={{ width: "100%" }}
              onChange={(item) => { selectCustomerHandler(item) }}
            >
              {select && select.map(item => (
                <Option key={item.clients_cust_id} value={item.clients_cust_id} label={item.name}>{item.name}</Option>
              ))}
            </Select>
          </div>
          <div className="reports_customer_search">
            <Button style={{background:"#38B6FF",borderRadius:"5px",color:"#fff",width:"100px"}} disabled={selectedCustomer?false:true} onClick={customerFilterHandler}>Search</Button>
          </div>
        </div>
        <List list={list} loading={load} customerName={searched} />

      </section> */}
    </AppLayout>
  )
}
else{
  return(<div className="spinner-main"><Spin/></div>)
}
}
const mapStateToProps = state => ({
  darkMode: state.data.isDarkMode,
  // loading: state.myYard.loading,
  // closedOutList: state.myYard.closedOutList,
  // closedOutCount: state.myYard.closedOutCount,
  billOutList: state.billReports.billOutList,
  billOutCount: state.billReports.billOutCount,
  success: state.billReports.success,
  loadingBillReportOut:state.billReports.loadingBillReportOut,
})

export default connect(mapStateToProps, { sampleFunction,  getClosedOutList, getBillReports })(Report)
